import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import { normalizeDocumentNode } from '@hotelplan/libs.graphql';
import {
  GetAuthB2BOverlayDocument,
  GetAuthB2BOverlayQuery,
  GetAuthB2BOverlayQueryVariables,
  useGetAuthB2BOverlayQuery,
} from 'graphql/auth/GetAuthB2BOverlay.generated';

function useB2BAuthOverlay() {
  const client = useApolloClient();
  const { data } = useGetAuthB2BOverlayQuery();

  return useMemo(
    () => ({
      authB2BOverlay: !!data?.authB2BOverlay,
      close() {
        try {
          client.writeQuery<
            GetAuthB2BOverlayQuery,
            GetAuthB2BOverlayQueryVariables
          >({
            query: normalizeDocumentNode(GetAuthB2BOverlayDocument),
            data: {
              __typename: 'Query',
              authB2BOverlay: false,
            },
          });
        } catch (e) {
          // ignore apollo write cache error
        }
      },
    }),
    [client, data]
  );
}

export default useB2BAuthOverlay;
