import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const SiteToolsWrapper = styled.div(({ theme: { space, colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    gap: [null, 2],
    padding: `0 ${space[3]}`,
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: 'borderColor',
    height: [null, '64px'],
    '.icon': {
      '&:hover,&:focus': {
        color: colors.primary,
      },
    },
  })
);
