import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { withActiveStyles } from '@hotelplan/components.common.mixins';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import {
  NavigationTarget,
  trackNavigationElementOpen,
  HelpModuleEventType,
  trackHelpModule,
} from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  HelpOverlay,
  HelpOverlayInactivityTimer,
  onOpenOverlayEvent,
} from 'components/domain/helpOverlay/HelpOverlay';
import { ESCAPE_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import { CONTACT_OVERLAY_EVENT } from 'components/domain/keyboard-navigation/SkipLinksComponent';
import { useListenKeyboardClick } from 'components/domain/keyboard-navigation/useListenKeyboardClick';
import { useHelpOverlayToggleStateContext } from 'components/domain/site-tool-buttons/index';
import {
  helpOverlayButtonActiveCss,
  siteToolsButtonCss,
} from 'components/domain/site-tool-buttons/SiteTools.styles';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';
import { FeatureList } from 'config/pageConfig.constants';

const IconButton = styled(Button).attrs({
  variant: 'iconBtn',
})<{ active: boolean }>(props =>
  withActiveStyles(
    props.active,
    siteToolsButtonCss(props),
    helpOverlayButtonActiveCss
  )
);

const HelpOverlayWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    bg: 'bgLightBlue',
    top: '101%',
    position: 'absolute',
    left: '0',
    zIndex: '10',
    boxShadow: 'cardShadow',
  })
);

const INACTIVE_TIME_TO_SHOW_HELP = 1000 * 60 * 3;

const trackContactsClick = (isHelpOverlayDropdownShown: boolean) => {
  if (isHelpOverlayDropdownShown) {
    trackHelpModule(HelpModuleEventType.CLOSE);
  } else {
    trackHelpModule(HelpModuleEventType.OPEN);
    trackNavigationElementOpen(NavigationTarget.CONTACT);
  }
};

const ContactsSiteTool = () => {
  const [t] = useTranslation();

  const helpOverlayButtonRef = useRef<HTMLButtonElement>(null);
  const helpOverlayDropdownRef = useRef<HTMLDivElement>(null);
  const { isEnabled } = useFeatureToggle();

  const {
    value: isHelpOverlayDropdownShown,
    enable: openHelpOverlayDropdown,
    disable: closeHelpOverlayDropdown,
    toggle: toggleHelpOverlayDropdown,
  } = useHelpOverlayToggleStateContext();

  useEffect(
    function indirectOverlayShown() {
      return onOpenOverlayEvent(openHelpOverlayDropdown);
    },
    [openHelpOverlayDropdown]
  );

  const listenSkipLinkClick = () => {
    openHelpOverlayDropdown();
    trackHelpModule(HelpModuleEventType.OPEN);
    helpOverlayButtonRef.current.focus();
  };

  const listenEscapeClick = (e: KeyboardEvent) => {
    if (e.key === ESCAPE_KEY && isHelpOverlayDropdownShown) {
      trackHelpModule(HelpModuleEventType.CLOSE);
      closeHelpOverlayDropdown();
    }
  };

  useIsomorphicLayoutEffect(() => {
    window.addEventListener(CONTACT_OVERLAY_EVENT, listenSkipLinkClick);

    return () => {
      window.removeEventListener(CONTACT_OVERLAY_EVENT, listenSkipLinkClick);
    };
  }, []);

  useListenKeyboardClick(isHelpOverlayDropdownShown, listenEscapeClick);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [helpOverlayDropdownRef, helpOverlayButtonRef],
    () => {
      if (!isHelpOverlayDropdownShown) return;
      trackHelpModule(HelpModuleEventType.CLOSE);
      closeHelpOverlayDropdown();
    }
  );

  return (
    <>
      <Tooltip text={t('common:phone.tooltip')}>
        {!isEnabled(FeatureList.CUSTOMER_SUPPORT_BOOSTER) && (
          <HelpOverlayInactivityTimer
            onIdle={() => {
              trackHelpModule(HelpModuleEventType.OPEN, true);
              openHelpOverlayDropdown();
            }}
            timeout={INACTIVE_TIME_TO_SHOW_HELP}
          />
        )}
        <IconButton
          active={isHelpOverlayDropdownShown}
          ref={helpOverlayButtonRef}
          testId={'header-contacts-buttons'}
          icon={{ name: 'phone' }}
          onClick={() => {
            trackContactsClick(isHelpOverlayDropdownShown);
            toggleHelpOverlayDropdown();
          }}
        >
          {t('common:phone.button')}
        </IconButton>
      </Tooltip>
      {isHelpOverlayDropdownShown && (
        <HelpOverlayWrapper ref={helpOverlayDropdownRef}>
          <HelpOverlay onClose={closeHelpOverlayDropdown} />
        </HelpOverlayWrapper>
      )}
    </>
  );
};

export default ContactsSiteTool;
