import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { withActiveStyles } from '@hotelplan/components.common.mixins';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import {
  ShareDetails,
  ShareForm,
} from '@hotelplan/components.common.share-form';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  NavigationTarget,
  trackNavigationElementOpen,
  ShareEventType,
  trackShare,
} from '@hotelplan/libs.tracking';
import { isIOS } from '@hotelplan/libs.utils';
import {
  helpOverlayButtonActiveCss,
  siteToolsButtonCss,
} from 'components/domain/site-tool-buttons/SiteTools.styles';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';

const IconButton = styled(Button).attrs({
  variant: 'iconBtn',
})<{ active: boolean }>(props =>
  withActiveStyles(
    props.active,
    siteToolsButtonCss(props),
    helpOverlayButtonActiveCss
  )
);

const ShareFormModalWrapper = styled(WhiteBoxModal).attrs({
  name: `shareForm` as string,
})(({ theme }) => ({
  '.modal-wrapper': theme.modals.wideModalWrapper,
  '.modal-body': theme.modals.commonModalBody,
}));

const ShareSiteTool = () => {
  const [t] = useTranslation('common');

  const [
    isShareFormModalShowed,
    setShareFormModalOpen,
    setShareFormModalClose,
  ] = useToggleState(false);

  function onShareClick() {
    trackShare(ShareEventType.OPEN);
    trackNavigationElementOpen(NavigationTarget.SHARING);
    setShareFormModalOpen();
  }

  return (
    <>
      <Tooltip text={t('share.tooltip')}>
        <IconButton
          active={false}
          testId={'header-share-buttons'}
          icon={{ name: isIOS ? 'share-ios' : 'share-android' }}
          onClick={onShareClick}
        >
          {t('share.button')}
        </IconButton>
      </Tooltip>
      <ShareFormModalWrapper
        title={t('shareModal.title')}
        show={isShareFormModalShowed}
        onClose={() => {
          trackShare(ShareEventType.CLOSE);
          setShareFormModalClose();
        }}
      >
        <ShareDetails />
        <ShareForm />
      </ShareFormModalWrapper>
    </>
  );
};

export default ShareSiteTool;
