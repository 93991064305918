import dynamic from 'next/dynamic';
import React from 'react';
import type { IB2BAuthPanelProps } from '@hotelplan/components.common.auth';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { HeaderLayout } from '@hotelplan/components.common.header';
import { LanguageControlDesktop } from '@hotelplan/components.common.language-control';
import {
  DesktopMenu,
  DesktopMenuSkeleton,
} from '@hotelplan/components.common.menu';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { usePageTransitionJustStarted } from '@hotelplan/libs.hooks';
import {
  NavigationTarget,
  trackLanguageSwitch,
  trackNavigationElementOpen,
  trackMenu,
  MenuEventType,
} from '@hotelplan/libs.tracking';
import useB2BAuthOverlay from 'components/domain/auth/useB2BAuthOverlay';
import useLanguage, {
  useGetLanguageBasedURLs,
} from 'components/domain/menu/useLanguage';
import ContactsSiteTool from 'components/domain/site-tool-buttons/ContactsSiteTool';
import HistorySiteTool from 'components/domain/site-tool-buttons/HistorySiteTool';
import MyAccountSiteTool from 'components/domain/site-tool-buttons/MyAccountSiteTool';
import ShareSiteTool from 'components/domain/site-tool-buttons/ShareSiteTool';
import { SiteToolsWrapper } from 'components/domain/site-tool-buttons/SiteTools.common';
import WishlistIconSiteTool from 'components/domain/site-tool-buttons/WishlistIconSiteTool';
import { FeatureList } from 'config/pageConfig.constants';
import MyAccountHeaderSection from './MyAccountHeaderSection';
import { useHeaderNavigation } from './useHeaderNavigation';

const B2BAuthPanelIdle = dynamic<IB2BAuthPanelProps>(
  () =>
    import('@hotelplan/components.common.auth').then(
      module => module.B2BAuthPanelIdle
    ),
  {
    ssr: false,
  }
);

const HeaderDesktop = () => {
  const { navigationLinks: links, loading, logo } = useHeaderNavigation();
  const { loading: languageLoading, urls } = useGetLanguageBasedURLs();
  const [, changeLanguage] = useLanguage();
  const transitionStarted = usePageTransitionJustStarted();

  const { authB2BOverlay, close } = useB2BAuthOverlay();
  const { channelType, logout } = useAuthentication();
  const { isEnabled } = useFeatureToggle();
  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  const isAuthenticated = channelType !== AuthChannelType.B2CAnonymous;
  const isB2B = channelType === AuthChannelType.B2B;

  const handleLanguageChange = (nextLanguage: string) => {
    trackLanguageSwitch(nextLanguage);
    void changeLanguage(nextLanguage);
  };

  return (
    <HeaderLayout logo={logo}>
      {loading ? (
        <DesktopMenuSkeleton />
      ) : (
        <DesktopMenu
          links={links}
          isSubMenuBlocked={transitionStarted}
          onLinkClick={(link, index, positionIndex) => {
            trackMenu({
              type: MenuEventType.MENU_ACTION,
              payload: {
                link: link?.as?.toString() || '',
                position: positionIndex ? [positionIndex, index] : index,
              },
            });
          }}
          internalLinkIconName={`chevron-right`}
        />
      )}
      <SiteToolsWrapper>
        <WishlistIconSiteTool />
        {!isMeinKontoEnabled ? (
          <MyAccountSiteTool
            onClick={() => {
              trackNavigationElementOpen(NavigationTarget.ACCOUNT);
            }}
          />
        ) : null}
        <ContactsSiteTool />
        <HistorySiteTool />
        <ShareSiteTool />
      </SiteToolsWrapper>
      {isMeinKontoEnabled && !isB2B ? (
        <MyAccountHeaderSection isAuthenticated={isAuthenticated} />
      ) : null}
      <LanguageControlDesktop
        loading={languageLoading}
        onChange={handleLanguageChange}
        urls={urls}
        className="language-control"
      />
      <B2BAuthPanelIdle
        authB2BOverlay={authB2BOverlay}
        channelType={channelType}
        close={close}
        login={close}
        logout={logout}
      />
    </HeaderLayout>
  );
};

export default React.memo(HeaderDesktop);
