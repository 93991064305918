import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';

export const useListenKeyboardClick = (
  isAddListener: boolean,
  listener: (e: KeyboardEvent) => void
): void => {
  useIsomorphicLayoutEffect(() => {
    if (isAddListener) {
      window.addEventListener('keyup', listener);
    }

    return () => {
      window.removeEventListener('keyup', listener);
    };
  }, [isAddListener]);
};
