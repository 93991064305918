import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAuthB2BOverlayQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAuthB2BOverlayQuery = {
  __typename?: 'Query';
  authB2BOverlay: boolean;
};

export const GetAuthB2BOverlayDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthB2BOverlay' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authB2BOverlay' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetAuthB2BOverlayQuery__
 *
 * To run a query within a React component, call `useGetAuthB2BOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthB2BOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthB2BOverlayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthB2BOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAuthB2BOverlayQuery,
    GetAuthB2BOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAuthB2BOverlayQuery,
    GetAuthB2BOverlayQueryVariables
  >(GetAuthB2BOverlayDocument, options);
}
export function useGetAuthB2BOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAuthB2BOverlayQuery,
    GetAuthB2BOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAuthB2BOverlayQuery,
    GetAuthB2BOverlayQueryVariables
  >(GetAuthB2BOverlayDocument, options);
}
export type GetAuthB2BOverlayQueryHookResult = ReturnType<
  typeof useGetAuthB2BOverlayQuery
>;
export type GetAuthB2BOverlayLazyQueryHookResult = ReturnType<
  typeof useGetAuthB2BOverlayLazyQuery
>;
export type GetAuthB2BOverlayQueryResult = Apollo.QueryResult<
  GetAuthB2BOverlayQuery,
  GetAuthB2BOverlayQueryVariables
>;
