import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { withActiveStyles } from '@hotelplan/components.common.mixins';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  NavigationTarget,
  trackNavigationElementOpen,
  HistoryEventType,
  trackHistory,
} from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import SearchHistory from 'components/domain/history/SearchHistory';
import {
  helpOverlayButtonActiveCss,
  siteToolsButtonCss,
} from 'components/domain/site-tool-buttons/SiteTools.styles';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';

const IconButton = styled(Button).attrs({
  variant: 'iconBtn',
})<{ active: boolean }>(props =>
  withActiveStyles(
    props.active,
    siteToolsButtonCss(props),
    helpOverlayButtonActiveCss
  )
);

const SearchHistoryWrapper = styled.div(
  sx2CssThemeFn({
    width: '375px',
    top: '101%',
    position: 'absolute',
    right: 'calc((100% - 1350px) / 2 + 1px)',
    zIndex: '10',
  })
);

const MySearchHistory = styled(SearchHistory)(({ theme: { space } }) => ({
  '.historyGroup': {
    marginBottom: space[4],
  },
  '.search-history-group-title': {
    marginBottom: space[2],
  },
}));

const trackHistoryClick = (isSearchHistoryDropdownShown: boolean) => {
  if (isSearchHistoryDropdownShown) {
    trackHistory(HistoryEventType.CLOSE);
  } else {
    trackHistory(HistoryEventType.OPEN);
    trackNavigationElementOpen(NavigationTarget.HISTORY);
  }
};

const HistorySiteTool = () => {
  const [t] = useTranslation();

  const searchHistoryDropdownRef = useRef<HTMLDivElement>(null);
  const searchHistoryButtonRef = useRef<HTMLButtonElement>(null);

  const [
    isSearchHistoryDropdownShown,
    ,
    closeSearchHistoryDropdown,
    toggleSearchHistoryDropdown,
  ] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [searchHistoryDropdownRef, searchHistoryButtonRef],
    () => {
      if (!isSearchHistoryDropdownShown) return;
      trackHistory(HistoryEventType.CLOSE);
      closeSearchHistoryDropdown();
    }
  );

  return (
    <>
      <Tooltip text={t('common:history.tooltip')}>
        <IconButton
          active={false}
          testId={'header-history-buttons'}
          icon={{ name: 'history' }}
          ref={searchHistoryButtonRef}
          onClick={() => {
            trackHistoryClick(isSearchHistoryDropdownShown);
            toggleSearchHistoryDropdown();
          }}
        >
          {t('common:history.button')}
        </IconButton>{' '}
      </Tooltip>

      {isSearchHistoryDropdownShown && (
        <SearchHistoryWrapper
          className="search-history"
          ref={searchHistoryDropdownRef}
        >
          <MySearchHistory
            onClick={() => {
              trackHistory(HistoryEventType.CLOSE);
              closeSearchHistoryDropdown();
            }}
          />
        </SearchHistoryWrapper>
      )}
    </>
  );
};

export default HistorySiteTool;
